import i18n, { t } from "i18next";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { Link, NavLink, useLocation } from "react-router-dom";
import authService from "services/auth.service";
import pageUtil from "utils/Page.util";
import Toast, { ToastData } from "./_common/Toast";
import { dateFormat } from "utils/Date.util";

class Params {
  isMember: boolean = false;
  isNoti: boolean = false;
  notiList = [];

  toastData: ToastData = new ToastData();
}
function NavBar() {
  const navigate = useNavigate();
  const location = useLocation();
  const [params, setParams] = useState(new Params());
  const [mounted, setMounted] = useState(false);

  //onMount
  useEffect(() => {
    console.log("NavBar onMount");

    setMounted(true);

    setTimeout(() => {
      const toggle = document.getElementById("toggle");
      const menuBar = document.getElementById("menuBar");

      if (toggle && menuBar) {
        menuBar.classList.remove("active");

        toggle.addEventListener("click", function () {
          menuBar.classList.toggle("active");
        });
      }


      const etcMenu = document.getElementById("etcMenu");
      const submenu = document.getElementById("submenu");

      if (etcMenu && submenu) {
        submenu.classList.remove("down");

        etcMenu.addEventListener("click", function () {
          submenu.classList.toggle("down");
        });
      }

    }, 350);
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    const menuBar = document.getElementById("menuBar");

    if (menuBar) menuBar.classList.remove("active");
  }, [location.pathname]); // 경로 변경 시 스크롤 이동

  function openMember() {
    setParams((prev) => ({
      ...prev,
      isMember: !prev.isMember,
      isNoti: false,
    }));
  }

  function openNoti() {
    setParams((prev) => ({
      ...prev,
      isMember: false,
      isNoti: !prev.isNoti,
    }));
  }

  async function signOut() {
    await authService.signOut(true);
    navigate("/login?r=" + location.pathname);
  }

  function getTitle() {
    let pathName = location.pathname;

    let title = "";
    if (pathName == "/") {
      title = t(`main:title`);
    }

    return title;
  }

  function goPage(path: string) {
    navigate(path);
  }

  return (
    <nav>
      {mounted && (
        <>
          <div className="menuBar" id="menuBar">
            <ul>
              <li className="flexBetween">
                <a
                  onClick={() => {
                    goPage("/");
                  }}
                >
                  다락메인
                </a>
                <span className="material-icons">chevron_right</span>
              </li>
              <li className="flexBetween">
                <NavLink
                  to={"instagram?username=hapjeong_darak"}
                  // state={{ username: "hapjeong_darak" }}
                >
                  {"@hapjeong_darak"}
                </NavLink>
                <span className="material-icons">chevron_right</span>
              </li>
              <li className="flexBetween">
                <NavLink
                  to={"instagram?username=darakparty"}
                  // state={{ username: "darakparty" }}
                >
                  {"@darakparty"}
                </NavLink>
                <span className="material-icons">chevron_right</span>
              </li>
              <li className="flexBetween">
                <NavLink
                  to={"instagram?username=darak_chaegjang"}
                  // state={{ username: "darak_chaegjang" }}
                >
                  {"@darak_chaegjang"}
                </NavLink>
                <span className="material-icons">chevron_right</span>
              </li>
              <li>
                <div className="flexBetween" id="etcMenu">
                  ETC
                </div>
                <ul className="submenu" id="submenu">
                  <li className="flexBetween">
                    <NavLink to="/board">게시판</NavLink>
                    <span className="material-icons">chevron_right</span>
                  </li>
                  <li className="flexBetween">
                    <NavLink to="/faq">FAQ</NavLink>
                    <span className="material-icons">chevron_right</span>
                  </li>
                  {/* <li className="flexBetween">
                    <NavLink to="/qna">Q&A</NavLink>
                    <span className="material-icons">chevron_right</span>
                  </li> */}
                </ul>
              </li>
            </ul>
          </div>
          <header className="header flexBetween">
            <div className="header_wrap">
              <h2>
                <a
                  onClick={() => {
                    goPage("/");
                  }}
                >
                  <img src={"/assets/app/img/logo_png.png"} alt="logo" />
                  합정다락
                </a>
              </h2>
              <ul className="flexEnd">
                {/* <li>
                <a onClick={() => { goPage("/") }}>합정다락</a>
              </li> */}
                <li>
                  <NavLink
                    to={"instagram?username=hapjeong_darak"}
                  // state={{ username: "hapjeong_darak" }}
                  >
                    {"@hapjeong_darak"}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={"instagram?username=darakparty"}
                  // state={{ username: "darakparty" }}
                  >
                    {"@darakparty"}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={"instagram?username=darak_chaegjang"}
                  // state={{ username: "darak_chaegjang" }}
                  >
                    {"@darak_chaegjang"}
                  </NavLink>
                </li>
                <li>
                  ETC
                  <ul className="submenu">
                    <li>
                      <NavLink to="/board">게시판</NavLink>
                    </li>
                    <li>
                      <NavLink to="/faq">FAQ</NavLink>
                    </li>
                    {/* <li>
                      <NavLink to="/qna">Q&A</NavLink>
                    </li> */}
                  </ul>
                </li>
              </ul>
              <div className="toggle" id="toggle">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
          </header>
        </>
      )}
    </nav>
  );
}

export default NavBar;
