import NavBar from "components/NavBar";
import SideBar from "components/SideBar";
import React, { lazy } from "react";
import { Route, Routes } from "react-router-dom";

import NoPage from "components/NoPage";
import Temp from "components/test/Temp";
import { ProtectedRoute } from "core/route.protected";
import { PublicRoute } from "core/route.public";
import Loading from "Loading";

import authService from "services/auth.service";

const Faq = lazy(() => import(`./components/faq/Faq`));
const Qna = lazy(() => import(`./components/qna/Qna`));
const Board = lazy(() => import(`./components/board/Board`));
const Instagram = lazy(() => import(`./components/instagram/Instagram`));
const Main = lazy(() => import(`./components/main/Main`));

const Login = lazy(() => {
  return Promise.all([
    import(/* webpackChunkName: "LoginComponent" */ "./components/login/Login"),
    new Promise((resolve) => setTimeout(resolve, 1000)),
  ]).then(([moduleExports]) => moduleExports);
});

class Layout extends React.Component {
  constructor(props: any) {
    super(props);

    for (let i = 0; i < document.body.classList.length; i++) {
      document.body.classList.remove(document.body.classList[0]);
    }
    document.body.style.minHeight = null;

    // document.body.classList.add("sidebar-mini");
    // document.body.classList.add("layout-fixed");
    // document.body.classList.add("layout-navbar-fixed");
  }

  public render() {
    return (
      <div className="wrap">
        <NavBar />

        <Routes>
          {/* <Route path='/' element={<Home />}></Route> */}

          <>
            <Route
              path="/"
              element={
                <PublicRoute>
                  <React.Suspense fallback={<Loading />}>
                    <Main />
                  </React.Suspense>
                </PublicRoute>
              }
            ></Route>

            <Route
              path="/instagram/*"
              element={
                <PublicRoute>
                  <React.Suspense fallback={<Loading />}>
                    <Instagram />
                  </React.Suspense>
                </PublicRoute>
              }
            ></Route>

            {/* <Route
              path="/qna/*"
              element={
                <PublicRoute>
                  <React.Suspense fallback={<Loading />}>
                    <Qna />
                  </React.Suspense>
                </PublicRoute>
              }
            ></Route> */}

            <Route
              path="/faq"
              element={
                <PublicRoute>
                  <React.Suspense fallback={<Loading />}>
                    <Faq />
                  </React.Suspense>
                </PublicRoute>
              }
            ></Route>

            <Route
              path="/board/*"
              element={
                <PublicRoute>
                  <React.Suspense fallback={<Loading />}>
                    <Board />
                  </React.Suspense>
                </PublicRoute>
              }
            ></Route>
          </>

          <Route path="*" element={<NoPage />}></Route>
        </Routes>
      </div>
    );
  }
}

export default Layout;
